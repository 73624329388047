import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';
import LogoIcon from "../../assets/svg/Logo";
import CloseIcon from "../../assets/svg/CloseIcon";

interface SidebarProps {
    toggleSidebar: (isOpen: boolean) => void;
    sidebarOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ toggleSidebar, sidebarOpen }) => {
    return (
        <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
            <SidebarHeader className="flexSpaceCenter">
                <div className="flexNullCenter">
                    <LogoIcon />
                    <h1 className="whiteColor font20" style={{ marginLeft: '15px' }}>
                        MentorizeNow
                    </h1>
                </div>
                <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
                    <CloseIcon />
                </CloseBtn>
            </SidebarHeader>

            <UlStyle className="flexNullCenter flexColumn">
                {['home', 'services', 'projects', 'blog', 'pricing', 'contact'].map((section) => (
                    <li key={section} className="semiBold font15 pointer">
                        <Link
                            onClick={() => toggleSidebar(!sidebarOpen)}
                            activeClass="active"
                            className="whiteColor"
                            style={{ padding: '10px 15px' }}
                            to={section}
                            spy={true}
                            smooth={true}
                            offset={-60}
                        >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </Link>
                    </li>
                ))}
            </UlStyle>
            <UlStyle className="flexSpaceCenter">
                <li className="semiBold font15 pointer">
                    <a href="/" style={{ padding: '10px 30px 10px 0' }} className="whiteColor">
                        Log in
                    </a>
                </li>
                <li className="semiBold font15 pointer flexCenter">
                    <a href="/" className="radius8 lightBg" style={{ padding: '10px 15px' }}>
                        Get Started
                    </a>
                </li>
            </UlStyle>
        </Wrapper>
    );
};

export default Sidebar;

const Wrapper = styled.nav<{ sidebarOpen: boolean }>`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? '0px' : '-400px')};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
