import React from "react";
import Footer from "../components/sections/Footer";
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/sections/Header";
import Services from "../components/sections/Services";
export default function Landing() {
    return (
        <>
            <TopNavbar />
            <Header />
            <Services />
            {/*<Projects />*/}
            {/*<Blog />*/}
            {/*<Pricing />*/}
            {/*<Contact />*/}
            <Footer/>
        </>
    );
}
