import React from 'react';
import logo from './logo.svg';
import './App.css';
import Landing from "./screens/Landing";

import "./style/flexboxgrid.min.css";
import './style/index.css';

function App() {
  return (
    <Landing/>
  );
}

export default App;
