import * as React from "react";

function SvgComponent(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={35.459}
            height={43.42}
            viewBox="0 0 64 64"
            {...props}
        >
            <g fill="#0b093b">
                <path d="M8 4v52h40V4H8zm36 48H12V8h32v44z" />
                <path d="M44 4v4h4V4h-4zm0 8v4h4v-4h-4zm0 8v4h4v-4h-4zm0 8v4h4v-4h-4zm0 8v4h4v-4h-4zm0 8v4h4v-4h-4zm-28-4v-4h-4v4h4zm0-8v-4h-4v4h4zm0-8v-4h-4v4h4zm0-8v-4h-4v4h4zm0-8v-4h-4v4h4zm0-8v-4h-4v4h4z" />
                <path d="M50 8v48h-4V8h4zM8 56h40v4H8z" />
            </g>
        </svg>
    );
}

export default SvgComponent;
