import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";

export default function Services() {
    return (
        <Wrapper id="services">
            {/*<div className="lightBg" style={{ padding: "50px 0" }}>*/}
            {/*    <div className="container">*/}
            {/*        /!*<ClientSlider />*!/*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="whiteBg" style={{ padding: "60px 0" }}>
                <div className="container">
                    <HeaderInfo>
                        <h1 className="font40 extraBold">Nuestros servicios</h1>
                        <p className="font13">
                            En nuestra empresa, conectamos a profesionales altamente capacitados con amplia experiencia para ofrecerte el asesoramiento que necesitas. Ya sea que quieras mejorar tu currículum, resolver dudas sobre tu carrera, o realizar una auditoría en tu empresa, estamos aquí para ayudarte a alcanzar tus objetivos. Una hora con nuestros expertos puede marcar la diferencia que estás buscando. No dejes pasar la oportunidad de obtener la guía que necesitas para avanzar con confianza.
                        </p>
                    </HeaderInfo>
                    <ServiceBoxRow className="flex">
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="browser"
                                title="Desarrollo en Sistemas"
                                subtitle="Ofrecemos servicios personalizados de auditoría y desarrollo de sistemas, adaptados a las necesidades específicas de cada cliente. Desde la creación de una landing page hasta la implementación de un sistema de inventarios completo, estamos preparados para desarrollar soluciones que impulsen tu negocio hacia el éxito."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="monitor"
                                title="Mentoría"
                                subtitle="Te acompañamos en tu desarrollo profesional a través de mentorías personalizadas. Si sientes que estás estancado o que tu crecimiento profesional se ha detenido, uno de nuestros expertos está aquí para ayudarte a desbloquear tu potencial y alcanzar tus metas. cubrimos las carreras de: Ingenieria en sitemas, Administración de empresas, Enfermería y carreras afines a las antes mencionadas"
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="browser"
                                title="Auditoría y capacitación"
                                subtitle="Si necesitas evaluar la calidad del código de tu equipo, asegurarte de que tu infraestructura de IT sea escalable, o verificar la seguridad de tus sistemas, podemos ayudarte con una auditoría integral que aborde todos estos aspectos."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox icon="printer"
                                        title="Cuidados de enfermería (El Salvador)"
                                        subtitle="Contamos con enfermeras capcitas en cuido de recien nacidos, adulto mayor y personas con discapacidad." />
                        </ServiceBoxWrapper>
                    </ServiceBoxRow>
                </div>
                <div className="lightBg">
                    <div className="container">
                        <Advertising className="flexSpaceCenter">
                            <AddLeft>
                                <h4 className="font15 semiBold">Un poco sobre nosotros</h4>
                                <h2 className="font40 extraBold">Una red de profesionales certificados</h2>
                                <p className="font12">
                                    Somos una plataforma que busca tener los mejores profesionales para acompañarte en tus proyectos
                                </p>
                                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                                    {/*<div style={{ width: "190px" }}>*/}
                                    {/*    <FullButton title="Get Started" action={() => alert("clicked")} />*/}
                                    {/*</div>*/}
                                    <div style={{width: "190px", marginLeft: "15px"}}>
                                        <a
                                            href="https://wa.me/50377476504?text=Quiero%20hablar%20con%20un%20experto"
                                            className="radius8 lightBg"
                                            style={{
                                                display: "block",
                                                padding: "10px 15px",
                                                textAlign: "center",
                                                border: "1px solid #000", // Esto se parece al estilo del botón con borde
                                                borderRadius: "8px",
                                                textDecoration: "none",
                                                color: "#000" // Ajusta el color según tu necesidad
                                            }}>
                                            Contactános
                                        </a>
                                    </div>

                                </ButtonsRow>
                            </AddLeft>
                            <AddRight>
                                <AddRightInner>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp1 className="flexCenter">
                                            <img src={AddImage1} alt="office"/>
                                        </AddImgWrapp1>
                                        <AddImgWrapp2>
                                            <img src={AddImage2} alt="office" />
                                        </AddImgWrapp2>
                                    </div>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp3>
                                            <img src={AddImage3} alt="office" />
                                        </AddImgWrapp3>
                                        <AddImgWrapp4>
                                            <img src={AddImage4} alt="office" />
                                        </AddImgWrapp4>
                                    </div>
                                </AddRightInner>
                            </AddRight>
                        </Advertising>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
