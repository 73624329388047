import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import HeaderImage from "../../assets/img/header-img.jpeg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";

export default function Header() {
    return (
        <Wrapper id="home" className="container flexSpaceCenter">
            <LeftSide className="flexCenter">
                <div>
                    <h1 className="extraBold font60">Libera tu potencial, abraza el cambio.</h1>
                    <HeaderP className="font13 semiBold">
                        Somos tu plataforma de guía integral para emprender el camino hacia el éxito. Si tienes dudas sobre qué estudiar o cómo avanzar en tu carrera, estamos aquí para ayudarte a descubrir tu verdadero potencial en áreas como:
                    </HeaderP>
                         <li className="font13 semiBold">Finanzas</li>
                        <li className="font13 semiBold">Ingeniería en Sistemas</li>
                        <li className="font13 semiBold">Salud</li>
                       <HeaderP className="font13 semiBold">
                           Además, ofrecemos consultorías especializadas para empresas, impulsando tu organización al siguiente nivel.
                           Con el respaldo de un equipo de profesionales altamente capacitados, te ofrecemos pláticas y sesiones privadas cara a cara, diseñadas para trazar un camino claro hacia tus metas.
                    </HeaderP>
                    <BtnWrapper>
                        <li className="semiBold font15 pointer flexCenter">
                            <a href="https://wa.me/50377476504?text=Quiero%20hablar%20con%20un%20experto"
                               className="radius8 lightBg" style={{padding: "10px 15px"}}>
                                Contáctanos
                            </a>
                        </li>
                    </BtnWrapper>
                </div>
            </LeftSide>
            <RightSide>
                <ImageWrapper>
                    <Img className="radius8" src={HeaderImage} alt="office" style={{zIndex: 9}}/>
                    <QuoteWrapper className="flexCenter darkBg radius8">
                        <QuotesWrapper>
                        <QuotesIcon />
                        </QuotesWrapper>
                        <div>
                            <p className="font15 whiteColor">
                                <em>Asesoramiento con personas competentes es el comienzo de la sabiduría.</em>
                            </p>
                            <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Andrew Carnegie</p>
                        </div>
                    </QuoteWrapper>
                    <DotsWrapper>
                        <Dots />
                    </DotsWrapper>
                </ImageWrapper>
                <GreyDiv className="lightBg"></GreyDiv>
            </RightSide>
        </Wrapper>
    );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 15px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;
